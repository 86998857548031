import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { SvgCheck, SvgCross, SvgDown } from "../../svg";
import { BtnDropdown } from "../common";
import { VKTimePicker } from "./";

export class VKFixedTimeForm extends Component {
  state = {
    item: { ...VKFixedTimeForm.defaultProps.item, ...this.props.item },
  };

  handleSelection = (field, value) => {
    const item = { ...this.state.item };
    item[field] = value;
    this.setState({ item });
  };

  save = () => {
    this.props.close();
    this.props.saveCallback(this.state.item);
  };

  render() {
    const { item } = this.state;
    const { close, links } = this.props;
    const selectedLink = _.find(links, ["id", item.link_id]) || { url: "нет" };
    const linkButton = (
      <span title={selectedLink.url}>
        {selectedLink.url.slice(0, 60)}
        <SvgDown width={9} height={6} className="toggler" />
      </span>
    );

    return (
      <tr key={`vk-time-${item.id}-form`}>
        <td />
        <td>
          <VKTimePicker
            time={item.app_time}
            callback={(value) => this.handleSelection("app_time", value)}
          />
        </td>
        <td>
          <VKTimePicker
            time={item.social_time}
            callback={(value) => this.handleSelection("social_time", value)}
          />
        </td>
        <td>
          <BtnDropdown btnClass="btn btn-link p-0" btnText={linkButton}>
            <ul className="dropdown">
              <li key="0" className={null === item.link_id ? "active" : null}>
                <button
                  className="btn btn-link p-0"
                  onClick={() => this.handleSelection("link_id", null)}
                >
                  нет
                </button>
              </li>
              {links.map((link) => (
                <li
                  key={link.id}
                  className={link.id === item.link_id ? "active" : null}
                >
                  <button
                    className="btn btn-link p-0"
                    onClick={() => this.handleSelection("link_id", link.id)}
                    title={link.url}
                  >
                    {link.url.slice(0, 60)}
                  </button>
                </li>
              ))}
            </ul>
          </BtnDropdown>
        </td>
        <td className="text-right text-nowrap action-buttons">
          <button
            className="btn btn-success btn-sm"
            onClick={this.save}
            title="Сохранить"
          >
            <SvgCheck width={14} height={12} className="fill-white" />
          </button>
          <button
            className="btn btn-primary btn-sm ml-1"
            onClick={close}
            title="Отменить"
          >
            <SvgCross width={14} height={12} className="fill-white" />
          </button>
        </td>
      </tr>
    );
  }

  static propTypes = {
    item: PropTypes.object,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    close: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    item: { app_time: "08:00", social_time: "08:01", link_id: null },
  };
}
