import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { baseUrl, ClickOutside } from "../../libs/";
import { SvgDots } from "../../svg/";
import { confirmEmail, resetPassword, removeAccount } from "../../actions";
import { Modal } from "../common";

class UserActionsDropdown extends Component {
  state = {
    showActionsDropDown: false,
    showRemoveConfirmation: false,
  };

  toggleActionsDropDown = () => {
    this.setState({ showActionsDropDown: !this.state.showActionsDropDown });
  };

  toggleRemoveConfirmation = (event) => {
    event.preventDefault();
    this.setState({
      showRemoveConfirmation: !this.state.showRemoveConfirmation,
    });
  };

  hideActionsDropDown = () => {
    if (this.state.showActionsDropDown) {
      this.setState({ showActionsDropDown: false });
    }
  };

  confirmEmail = (event) => {
    event.preventDefault();
    this.props
      .confirmEmail(this.props.user.id)
      .then((data) => this.setState(data.user));
  };

  resetPassword = (event) => {
    event.preventDefault();
    this.props
      .resetPassword(this.props.user.id)
      .then((data) => this.setState(data.user));
  };

  removeAccount = () => {
    this.props.removeAccount(this.props.user.id).then((data) => {
      if (this.props.callback) {
        this.props.callback(data.user);
      }
      this.setState({ showRemoveConfirmation: false });
    });
  };

  render() {
    const { showActionsDropDown, showRemoveConfirmation } = this.state;
    const { user, hasAdminAccess } = this.props;

    return (
      <Fragment>
        <ClickOutside
          onBlur={this.hideActionsDropDown}
          isOpen={showActionsDropDown}
        >
          <button
            type="button"
            onClick={this.toggleActionsDropDown}
            className="btn primary-light btn-dropdown ml-15 px-1"
          >
            <SvgDots className="fill-primary" />
            {showActionsDropDown && (
              <ul className="dropdown">
                <li>
                  <Link
                    to={`${baseUrl}/users/${user.id}/rating_awards`}
                    target="_blank"
                  >
                    История начислений рейтинга
                  </Link>
                </li>
                {hasAdminAccess ? (
                  <Fragment>
                    <li>
                      <Link
                        to={`${baseUrl}/users/${user.id}/history`}
                        target="_blank"
                      >
                        История изменений
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${baseUrl}/users/${user.id}/actions/posts`}
                        target="_blank"
                      >
                        История действий
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${baseUrl}/social_accounts?user=${user.id}`}
                        target="_blank"
                      >
                        Соц. аккаунты
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${baseUrl}/users/${user.id}/reset_password`}
                        target="_blank"
                        onClick={this.resetPassword}
                      >
                        Восстановить пароль
                      </Link>
                    </li>
                    {user.email && !user.verified_email && (
                      <li>
                        <Link
                          to={`${baseUrl}/users/${user.id}/confirm_email`}
                          target="_blank"
                          onClick={this.confirmEmail}
                        >
                          Подтвердить e-mail
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link onClick={(e) => {
                        e.preventDefault();
                        if (this.props.toggleAvatarUpdate) {
                          this.props.toggleAvatarUpdate();
                        }
                      }}>
                        {user.avatar_update_blocked
                          ? "Разблокировать обновление аватара"
                          : "Заблокировать обновление аватара"}
                      </Link>
                    </li>
                  </Fragment>
                ) : (
                  <li>
                    <Link
                      to={`${baseUrl}/users/${user.id}/actions/comments`}
                      target="_blank"
                    >
                      История действий
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </button>
        </ClickOutside>
        {showRemoveConfirmation && (
          <Modal
            key="confirm"
            title={`Удаление аккаунта '${user.fullname_or_anonymus}'`}
            toggleModal={this.toggleRemoveConfirmation}
            small
          >
            <div>
              <div className="mb-1">
                Вы действительно хотите удалить данный аккаунт?
              </div>
              <div className="mt-2 text-right">
                <button
                  type="button"
                  onClick={this.toggleRemoveConfirmation}
                  className="btn btn-primary mr-1"
                >
                  Отмена
                </button>
                <button
                  type="button"
                  onClick={() => this.removeAccount(user.id)}
                  className="btn btn-secondary"
                >
                  Удалить
                </button>
              </div>
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    callback: PropTypes.func,
    hasAdminAccess: PropTypes.bool.isRequired,
    confirmEmail: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    removeAccount: PropTypes.func.isRequired,
    toggleAvatarUpdate: PropTypes.func,
  };
}

const mapStateToProps = (state) => ({
  hasAdminAccess: state.user.hasAdminAccess,
});

const mapDispatchToProps = (dispatch) => ({
  confirmEmail: (id) => dispatch(confirmEmail(id)),
  resetPassword: (id) => dispatch(resetPassword(id)),
  removeAccount: (id) => dispatch(removeAccount(id)),
});

export const ActionsDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserActionsDropdown);
