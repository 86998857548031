import React, { useCallback, useEffect, useRef } from "react";
import { mergeRefs } from "../../../libs";

export const Textarea = React.forwardRef(({ cursor, ...props }, ref) => {
  const elem = useRef(null);

  const setHeight = useCallback(() => {
    if (elem.current && !props.rows) {
      const styles = window.getComputedStyle(elem.current);

      const bW =
        parseFloat(styles.borderTopWidth) +
        parseFloat(styles.borderBottomWidth);
      const pT = parseFloat(styles.paddingTop);
      const pB = parseFloat(styles.paddingBottom);
      const lH = parseFloat(styles.lineHeight);
      const height = pT + pB + lH + bW;

      elem.current.style.height = `${height}px`;

      if (props.value) {
        elem.current.style.height = `${elem.current.scrollHeight + bW}px`;
      }
    }
  }, [props.rows, props.value]);

  useEffect(() => {
    setHeight();
  }, [setHeight]);

  // focus on mount
  useEffect(() => {
    elem.current?.focus();
  }, []);

  useEffect(() => {
    if (cursor && elem.current) {
      elem.current.selectionStart = elem.current.selectionEnd = cursor;
    }
  }, [props.value, cursor]);

  useEffect(() => {
    if (elem.current) {
      const scrollTop = elem.current.scrollTop;
      setHeight();
      elem.current.scrollTop = scrollTop;
    }
  }, [props.value]);

  return (
    <textarea
      {...props}
      className="form-control"
      ref={mergeRefs([elem, ref])}
    />
  );
});
