import { DataLayer } from "../../libs/";

export const loadTelegramScheduledSecrets = () => async (dispatch) => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/secrets/telegram_scheduled.json" })
    .then((response) => {
      newState.telegramSecrets = response.telegram_secrets;
      const payload = { links: response.telegram_links, timers: response.telegram_timers, fixed_timers: response.telegram_fixed_timers };
      dispatch({ type: "SET_TELEGRAM_SETTINGS", payload });
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
