import { DataLayer } from "../../libs/";

export const loadVkScheduledSecrets = () => async (dispatch) => {
  const newState = {
    loading: false,
    loadingError: false,
  };

  await DataLayer.get({ path: "/secrets/vk_scheduled.json" })
    .then((response) => {
      newState.vkSecrets = response.vk_secrets;
      const payload = { links: response.vk_links, timers: response.vk_timers, fixed_timers: response.vk_fixed_timers };
      dispatch({ type: "SET_VK_SETTINGS", payload });
    })
    .catch(() => {
      newState.loadingError = true;
    });

  return newState;
};
