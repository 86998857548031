import React from "react";
import PropTypes from "prop-types";

import {Link, NavLink} from "react-router-dom";
import { connect } from "react-redux";
import {baseUrl} from "../../libs";

const Tabs = [
  { name: "general", title: "Основные" },
  { name: "timers", title: "Свободное расписание" },
  { name: "fixed_timers", title: "Фиксированное расписание" },
];

const TelegramTabsContent = ({ telegramTab, addButtonTitle, addButtonCallback }) => (
  <>
    <div className="row mb-1">
      <div className="col secondary-nav border-0">
        <nav className="mt-0">
          {Tabs.map((tab) => (
            <Link
              to={`#${tab.name}`}
              key={tab.name}
              className={telegramTab === tab.name ? "active" : null}
            >
              {tab.title}
            </Link>
          ))}
        </nav>
      </div>
      {addButtonTitle && (
        <div className="col-auto">
          <button className="btn btn-primary" onClick={addButtonCallback}>
            Добавить {addButtonTitle}
          </button>
        </div>
      )}
    </div>
  </>
);

TelegramTabsContent.propTypes = {
  telegramTab: PropTypes.string.isRequired,
  addButtonTitle: PropTypes.string,
  addButtonCallback: PropTypes.func,
};

const mapStateToProps = (state) => ({
  telegramTab: state.filters.telegram.telegramTab,
});

export const TelegramTabs = connect(mapStateToProps)(TelegramTabsContent);
