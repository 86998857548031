import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Settings from "./settings";
import { VKTabs, VKTimeForm, VKTimeItem } from "./";
import {VKFixedTimeForm} from "./vk-fixed-time-form";
import {VKFixedTimeItem} from "./vk-fixed-time-item";

export class VKFixedTimers extends Settings {
  toggleForm = () => {
    this.setState({ showAddForm: !this.state.showAddForm });
  };

  saveTime = (timeObj) => {
    const data = { ...this.state.data };
    const fixedTimers = data.fixed_timers ? [...data.fixed_timers] : [];
    data.fixed_timers = this.updateTimers(timeObj, fixedTimers);
    this.setState({ data, showAddForm: false }, () => this.save("vk"));
  };

  deleteTime = (id) => {
    const data = { ...this.state.data };
    data.fixed_timers = [...data.fixed_timers];
    _.remove(data.fixed_timers, ["id", id]);
    this.setState({ data }, () => this.save("vk"));
  };

  updateTimers = (item, list) => {
    const indexById = _.findIndex(list, ["id", item.id]);
    const indexByAppTime = _.findIndex(list, ["app_time", item.app_time]);
    const indexBySocialTime = _.findIndex(list, ["social_time", item.social_time]);

    if (indexById >= 0) {
      list[indexById] = item;
    } else if (indexByAppTime >= 0 || indexBySocialTime >= 0) {
      alert("Ошибка! Уже существует таймер с таким временем.");
    } else {
      list.push(item);
    }

    const sortedList = list.sort((a, b) => {
      const [ah, am] = a.app_time.split(":").map((v) => parseInt(v, 10));
      const [bh, bm] = b.app_time.split(":").map((v) => parseInt(v, 10));
      const aVal = (ah < 8 ? ah + 24 : ah) * 100 + am;
      const bVal = (bh < 8 ? bh + 24 : bh) * 100 + bm;
      return aVal - bVal;
    });

    return sortedList.map((timeObj, id) => {
      return { ...timeObj, id };
    });
  };

  render() {
    const {
      showAddForm,
      data: { fixed_timers, links },
    } = this.state;
    return (
      <div className="settings-item bg-white p-2">
        <VKTabs
          addButtonTitle="время публикации"
          addButtonCallback={this.toggleForm}
        />

        <table className="table table-responsive table-striped table-sm table-hover">
          <colgroup>
            <col width="50" />
            <col width="180" />
            <col width="180" />
            <col />
            <col width="90" />
          </colgroup>
          <thead>
          <tr>
            <th>№</th>
            <th>Время в приложении</th>
            <th>Время в соцсети</th>
            <th>Ссылка</th>
            <th />
          </tr>
          </thead>
          <tbody>
          {showAddForm && (
            <VKFixedTimeForm
              key="add"
              links={links}
              close={this.toggleForm}
              saveCallback={this.saveTime}
            />
          )}
          {fixed_timers &&
            fixed_timers.map((item, index) => (
              <VKFixedTimeItem
                key={item.id}
                id={index + 1}
                item={item}
                links={links}
                saveCallback={this.saveTime}
                deleteCallback={this.deleteTime}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
    saveCallback: PropTypes.func.isRequired,
  };
}
